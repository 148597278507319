// @ts-check
// TODO Extract this to its own library

const characterReplacementMap = {
  "\u00C0": "A",
  "\u00C1": "A",
  "\u00C2": "A",
  "\u00C3": "A",
  "\u00C4": "A",
  "\u00C5": "A",
  "\u00C6": "AE",
  "\u00C7": "C",
  "\u00C8": "E",
  "\u00C9": "E",
  "\u00CA": "E",
  "\u00CB": "E",
  "\u00CC": "I",
  "\u00CD": "I",
  "\u00CE": "I",
  "\u00CF": "I",
  "\u00D0": "D",
  "\u00D1": "N",
  "\u00D2": "O",
  "\u00D3": "O",
  "\u00D4": "O",
  "\u00D5": "O",
  "\u00D6": "O",
  "\u00D8": "O",
  "\u00D9": "U",
  "\u00DA": "U",
  "\u00DB": "U",
  "\u00DC": "U",
  "\u00DD": "Y",
  "\u00DE": "Th",
  "\u00DF": "ss",
  "\u00E0": "a",
  "\u00E1": "a",
  "\u00E2": "a",
  "\u00E3": "a",
  "\u00E4": "a",
  "\u00E5": "a",
  "\u00E6": "ae",
  "\u00E7": "c",
  "\u00E8": "e",
  "\u00E9": "e",
  "\u00EA": "e",
  "\u00EB": "e",
  "\u00EC": "i",
  "\u00ED": "i",
  "\u00EE": "i",
  "\u00EF": "i",
  "\u00F0": "d",
  "\u00F1": "n",
  "\u00F2": "o",
  "\u00F3": "o",
  "\u00F4": "o",
  "\u00F5": "o",
  "\u00F6": "o",
  "\u00F8": "o",
  "\u00F9": "u",
  "\u00FA": "u",
  "\u00FB": "u",
  "\u00FC": "u",
  "\u00FD": "y",
  "\u00FE": "th",
  "\u00FF": "y",
  "\u0100": "A",
  "\u0101": "a",
  "\u0102": "A",
  "\u0103": "a",
  "\u0104": "A",
  "\u0105": "a",
  "\u0106": "C",
  "\u0107": "c",
  "\u0108": "C",
  "\u0109": "c",
  "\u010A": "C",
  "\u010B": "c",
  "\u010C": "C",
  "\u010D": "c",
  "\u010E": "D",
  "\u010F": "d",
  "\u0110": "D",
  "\u0111": "d",
  "\u0112": "E",
  "\u0113": "e",
  "\u0114": "E",
  "\u0115": "e",
  "\u0116": "E",
  "\u0117": "e",
  "\u0118": "E",
  "\u0119": "e",
  "\u011A": "E",
  "\u011B": "e",
  "\u011C": "G",
  "\u011D": "g",
  "\u011E": "G",
  "\u011F": "g",
  "\u0120": "G",
  "\u0121": "g",
  "\u0122": "G",
  "\u0123": "g",
  "\u0124": "H",
  "\u0125": "h",
  "\u0126": "H",
  "\u0127": "h",
  "\u0128": "I",
  "\u0129": "i",
  "\u012A": "I",
  "\u012B": "i",
  "\u012C": "I",
  "\u012D": "i",
  "\u012E": "I",
  "\u012F": "i",
  "\u0130": "I",
  "\u0131": "i",
  "\u0132": "IJ",
  "\u0133": "ij",
  "\u0134": "J",
  "\u0135": "j",
  "\u0136": "K",
  "\u0137": "k",
  "\u0138": "k",
  "\u0139": "L",
  "\u013A": "l",
  "\u013B": "L",
  "\u013C": "l",
  "\u013D": "L",
  "\u013E": "l",
  "\u013F": "L",
  "\u0140": "l",
  "\u0141": "L",
  "\u0142": "l",
  "\u0143": "N",
  "\u0144": "n",
  "\u0145": "N",
  "\u0146": "n",
  "\u0147": "N",
  "\u0148": "n",
  "\u0149": "n",
  "\u014A": "N",
  "\u014B": "n",
  "\u014C": "O",
  "\u014D": "o",
  "\u014E": "O",
  "\u014F": "o",
  "\u0150": "O",
  "\u0151": "o",
  "\u0152": "OE",
  "\u0153": "oe",
  "\u0154": "R",
  "\u0155": "r",
  "\u0156": "R",
  "\u0157": "r",
  "\u0158": "R",
  "\u0159": "r",
  "\u015A": "S",
  "\u015B": "s",
  "\u015C": "S",
  "\u015D": "s",
  "\u015E": "S",
  "\u015F": "s",
  "\u0160": "S",
  "\u0161": "s",
  "\u0162": "T",
  "\u0163": "t",
  "\u0164": "T",
  "\u0165": "t",
  "\u0166": "T",
  "\u0167": "t",
  "\u0168": "U",
  "\u0169": "u",
  "\u016A": "U",
  "\u016B": "u",
  "\u016C": "U",
  "\u016D": "u",
  "\u016E": "U",
  "\u016F": "u",
  "\u0170": "U",
  "\u0171": "u",
  "\u0172": "U",
  "\u0173": "u",
  "\u0174": "W",
  "\u0175": "w",
  "\u0176": "Y",
  "\u0177": "y",
  "\u0178": "Y",
  "\u0179": "Z",
  "\u017A": "z",
  "\u017B": "Z",
  "\u017C": "z",
  "\u017D": "Z",
  "\u017E": "z",
  "\u017F": "s",
  "\u0180": "b",
  "\u0181": "B",
  "\u0182": "B",
  "\u0183": "b",
  "\u0184": "6",
  "\u0185": "6",
  "\u0186": "O",
  "\u0187": "C",
  "\u0188": "c",
  "\u0189": "D",
  "\u018A": "D",
  "\u018B": "D",
  "\u018C": "d",
  "\u018D": "d",
  "\u018E": "E",
  "\u018F": "e",
  "\u0190": "E",
  "\u0191": "F",
  "\u0192": "f",
  "\u0193": "G",
  "\u0194": "G",
  "\u0195": "H",
  "\u0196": "h",
  "\u0197": "I",
  "\u0198": "I",
  "\u0199": "K",
  "\u019A": "k",
  "\u019B": "l",
  "\u019C": "l",
  "\u019D": "N",
  "\u019E": "n",
  "\u019F": "N",
  "\u01A0": "O",
  "\u01A1": "O",
  "\u01A2": "O",
  "\u01A3": "o",
  "\u01A4": "o",
  "\u01A5": "o",
  "\u01A6": "P",
  "\u01A7": "p",
  "\u01A8": "R",
  "\u01A9": "R",
  "\u01AA": "R",
  "\u01AB": "r",
  "\u01AC": "r",
  "\u01AD": "R",
  "\u01AE": "S",
  "\u01AF": "S",
  "\u01B0": "S",
  "\u01B1": "s",
  "\u01B2": "T",
  "\u01B3": "t",
  "\u01B4": "T",
  "\u01B5": "t",
  "\u01B6": "T",
  "\u01B7": "U",
  "\u01B8": "u",
  "\u01B9": "U",
  "\u01BA": "U",
  "\u01BB": "u",
  "\u01BC": "W",
  "\u01BD": "w",
  "\u01BE": "Y",
  "\u01BF": "Z",
  "\u01C0": "Z",
  "\u01C1": "z",
  "\u01C2": "Z",
  "\u01C3": "z",
  "\u01C4": "D",
  "\u01C5": "D",
  "\u01C6": "D",
  "\u01C7": "d",
  "\u01C8": "d",
  "\u01C9": "L",
  "\u01CA": "L",
  "\u01CB": "L",
  "\u01CC": "l",
  "\u01CD": "l",
  "\u01CE": "N",
  "\u01CF": "N",
  "\u01D0": "N",
  "\u01D1": "n",
  "\u01D2": "n",
  "\u01D3": "N",
  "\u01D4": "O",
  "\u01D5": "O",
  "\u01D6": "O",
  "\u01D7": "O",
  "\u01D8": "O",
  "\u01D9": "U",
  "\u01DA": "U",
  "\u01DB": "U",
  "\u01DC": "U",
  "\u01DD": "u",
  "\u01DE": "u",
  "\u01DF": "u",
  "\u01E0": "U",
  "\u01E1": "W",
  "\u01E2": "W",
  "\u01E3": "Y",
  "\u01E4": "Y",
  "\u01E5": "Y",
  "\u01E6": "Z",
  "\u01E7": "Z",
  "\u01E8": "Z",
  "\u01E9": "z",
  "\u01EA": "z",
  "\u01EB": "z",
  "\u01EC": "E",
  "\u01ED": "e",
  "\u01EE": "I",
  "\u01EF": "i",
  "\u01F0": "O",
  "\u01F1": "o",
  "\u01F2": "O",
  "\u01F3": "o",
  "\u01F4": "O",
  "\u01F5": "o",
  "\u01F6": "O",
  "\u01F7": "o",
  "\u01F8": "O",
  "\u01F9": "U",
  "\u01FA": "u",
  "\u01FB": "U",
  "\u01FC": "u",
  "\u01FD": "u",
  "\u01FE": "U",
  "\u01FF": "u",
  "\u0200": "A",
  "\u0201": "a",
  "\u0202": "A",
  "\u0203": "a",
  "\u0204": "E",
  "\u0205": "e",
  "\u0206": "E",
  "\u0207": "e",
  "\u0208": "I",
  "\u0209": "i",
  "\u020A": "I",
  "\u020B": "i",
  "\u020C": "O",
  "\u020D": "o",
  "\u020E": "O",
  "\u020F": "o",
  "\u0210": "R",
  "\u0211": "r",
  "\u0212": "R",
  "\u0213": "r",
  "\u0214": "U",
  "\u0215": "u",
  "\u0216": "U",
  "\u0217": "u",
  "\u0218": "S",
  "\u0219": "s",
  "\u021A": "T",
  "\u021B": "t",
  "\u021C": "Y",
  "\u021D": "y",
  "\u021E": "H",
  "\u021F": "h",
  "\u0220": "N",
  "\u0221": "d",
  "\u0222": "OU",
  "\u0223": "ou",
  "\u0224": "Z",
  "\u0225": "z",
  "\u0226": "A",
  "\u0227": "a",
  "\u0228": "E",
  "\u0229": "e",
  "\u022A": "O",
  "\u022B": "o",
  "\u022C": "O",
  "\u022D": "o",
  "\u022E": "O",
  "\u022F": "o",
  "\u0230": "O",
  "\u0231": "o",
  "\u0232": "Y",
  "\u0233": "y",
  "\u0234": "l",
  "\u0235": "n",
  "\u0236": "t",
  "\u0237": "j",
  "\u0238": "db",
  "\u0239": "qp",
  "\u023A": "A",
  "\u023B": "C",
  "\u023C": "c",
  "\u023D": "L",
  "\u023E": "T",
  "\u023F": "s",
  "\u0240": "z",
  "\u0241": "B",
  "\u0242": "U",
  "\u0243": "V",
  "\u0244": "E",
  "\u0245": "e",
  "\u0246": "J",
  "\u0247": "j",
  "\u0248": "Q",
  "\u0249": "q",
  "\u024A": "R",
  "\u024B": "r",
  "\u024C": "Y",
  "\u024D": "y",
  "\u024E": "a",
  "\u024F": "a",
  "\u1E02": "B",
  "\u1E03": "b",
  "\u1E0A": "D",
  "\u1E0B": "d",
  "\u1E1E": "F",
  "\u1E1F": "f",
  "\u1E40": "M",
  "\u1E41": "m",
  "\u1E56": "P",
  "\u1E57": "p",
  "\u1E60": "S",
  "\u1E61": "s",
  "\u1E6A": "T",
  "\u1E6B": "t",
  "\u1E80": "W",
  "\u1E81": "w",
  "\u1E82": "W",
  "\u1E83": "w",
  "\u1E84": "W",
  "\u1E85": "w",
  "\u1E9B": "W",
  "\u1EF2": "Y",
  "\u1EF3": "y",
  "\u0259": "a",
  "\u027C": "c",
  "\u0292": "f",
};

/**
 * Replace all non latin-1 characters in the string.
 * @param {string} str - Input string with non latin-1 characters.
 * @returns {string} - Output string without the non latin-1 characters.
 */
export default function replaceAll(str) {
  return str.replace(
    /[\u00C0-\u1FFF]/g,
    (c) => characterReplacementMap[c] || c,
  );
}
